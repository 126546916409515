import { FC, ReactNode } from 'react'
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  Typography,
} from '@mui/material'
import clsx from 'clsx'

// Assets
import { ReactComponent as CheckboxAllIcon } from 'assets/icon/designsystem/check_box_all.svg'
import { ReactComponent as CheckboxBorderIcon } from 'assets/icon/designsystem/check_box_outline_blank.svg'
import { ReactComponent as CheckboxIcon } from 'assets/icon/designsystem/check_box.svg'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'

// Hooks
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

// Styles
import scssVariables from 'assets/theme/coreVariablesV2.module.scss'
import styles from './Checkbox.module.scss'

// UI
import { Tooltip } from '../tooltip/Tooltip'
import { TypographyEllipsis } from '../typography/TypographyEllipsis'

type CheckboxProps = {
  label?: string
  tooltip?: string | ReactNode
}

export const Checkbox: FC<MuiCheckboxProps & CheckboxProps> = (props) => {
  const { checked, className, disabled, indeterminate, label, tooltip, onChange, ...restProps } =
    props

  const { desktop, mobile, tabletAll } = useBreakpoints()

  return (
    <div className={clsx(styles.checkbox, className)}>
      <MuiCheckbox
        checked={checked}
        checkedIcon={<CheckboxIcon color={scssVariables.red100pct} className={styles.icon} />}
        className={styles.elem}
        disabled={disabled}
        icon={<CheckboxBorderIcon color={scssVariables.darkGray34K} className={styles.icon} />}
        indeterminate={indeterminate}
        indeterminateIcon={
          <CheckboxAllIcon color={scssVariables.red100pct} className={styles.icon} />
        }
        onChange={onChange}
        {...restProps}
      />
      {label && (desktop || (!desktop && !tooltip)) && (
        <TypographyEllipsis
          noWrap
          color={disabled ? scssVariables.darkGray34K : undefined}
          variant="bodyBold"
        >
          {label}
        </TypographyEllipsis>
      )}
      {label && tooltip && (
        <Tooltip activateOnClick={mobile || tabletAll} title={tooltip}>
          {desktop ? (
            <InfoIcon
              className={styles.tooltipIcon}
              color={disabled ? scssVariables.darkGray34K : undefined}
            />
          ) : (
            <Typography
              className={styles.tooltipTitle}
              color={disabled ? scssVariables.darkGray34K : undefined}
              variant="bodyBold"
            >
              {label}
            </Typography>
          )}
        </Tooltip>
      )}
    </div>
  )
}
